import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"
import newIcon from "../../assets/images/new_icon.gif"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import demo from "../../assets/videos/demoEtud.mp4"
class NewsGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nouveautes: [
        {
          id: 1,
          title: "L'emploi du temps",
          link: "/EmploiEnseignant",
          date: "04/03/2022",
          image:
            "https://www.wikihow.com/images_en/thumb/2/26/Do-Homework-Step-6-Version-3.jpg/v4-460px-Do-Homework-Step-6-Version-3.jpg.webp",
          description: "Emploi du temps de l'enseignant",
        },
        {
          id: 2,
          title: "L'emploi des examens",
          link: "/CalendrierExam",
          date: "05/03/2022",
          image:
            "https://www.wikihow.com/images_en/thumb/2/26/Do-Homework-Step-6-Version-3.jpg/v4-460px-Do-Homework-Step-6-Version-3.jpg.webp",
          description: "Emploi des examens de l'enseignant",
        },
      ],
    }
  }

  render() {
    return (
      <React.Fragment>
        <Col>
          <Card>
            <div>
              <Row className="justify-content-center">
                <Col xl={10}>
                  <div>
                    <Row className="align-items-center">
                      {/*<div className="mt-4">*/}
                      {/*  <div>*/}
                      {/*    <h2*/}
                      {/*      style={{ color: "#556ee6", textAlign: "center" }}*/}
                      {/*      className="mb-0"*/}
                      {/*    >*/}
                      {/*      /!* {this.props.t("À LA UNE")} *!/*/}
                      {/*      {this.props.t("Bienvenue dans l'espace Etudiant")}*/}
                      {/*    </h2>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="mt-4">*/}
                      {/*  <div>*/}
                      {/*    <h5 style={{}} className="">*/}
                      {/*      /!* {this.props.t("À LA UNE")} *!/*/}
                      {/*      {this.props.t(*/}
                      {/*        "vous êtes invités à consulter ce guide d'utilisation"*/}
                      {/*      )}*/}
                      {/*    </h5>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="mt-4">*/}
                      {/*  <div>*/}
                      {/*    <video*/}
                      {/*      src={demo}*/}
                      {/*      width="100%"*/}
                      {/*      controls="controls"*/}
                      {/*      autoPlay="true"*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </Row>
                    {/* <hr className="mb-4" /> */}
                    {/* <Row>
                        {this.state.nouveautes.map(element => (
                          <Col key={element.id} sm={4}>
                            <Card className="p-1 border shadow-none">
                              <div className="p-3">
                                <h5>
                                  <Link to="/blog-details" className="text-dark">
                                    {element.title}
                                  </Link>
                                </h5>
                                <img src={newIcon} alt="" />
                                <p className="text-muted mb-0">{element.date}</p>
                              </div>
                              <div className="position-relative">
                                <img
                                  src={element.image}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                              <div className="p-3">
                                <p>{element.description}</p>
                                <div>
                                  <Link
                                    to={element.link}
                                    className="text-primary"
                                  >
                                    {this.props.t("Lire plus")}
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
NewsGrid.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(NewsGrid))
